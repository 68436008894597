@use "styles/utils/media.module";
// @use "./../Button/shared.module.scss";

.navigation {
	position: fixed;
	width: 100%;
	z-index: 200;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 114px;
	padding: 0 24px;
	top: 0;

	@include media.min-width(lg) {
		gap: 40px;
		padding: 0 32px;
	}

	&__logo {
		z-index: 202;
	}

	button {
		padding: 0;
	}
}
