@use "styles/utils/media.module";

.text {
	font: var(--font-body);
	letter-spacing: 0.02em;
	color: var(--wsw-75);
}

.bold {
	font: inherit;
	font-weight: bold;
}

.italic {
	font: inherit;
	font-style: italic;
}

.small {
	font: var(--font-body-sm);
	color: var(--wsw-transparent);
	a {
		font-weight: 600;
		text-decoration: underline;
		color: var(--wsw-100);
	}
}

.large {
	font: var(--font-body-lg);
}
