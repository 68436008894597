@use "styles/utils/media.module";
@use "./shared.module.scss";

.button {
	@extend %button;
}

// Button styling

.primary,
.secondary,
.tertiary,
#rcc-confirm-button {
	all: unset;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 16px;
	color: var(--wsw-100);
	justify-content: center;

	span {
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: inherit;
	}

	&:enabled {
		&:hover,
		&:focus {
			.svgWrapper {
				@extend %transform--hover;
			}
		}

		&:active {
			.svgWrapper {
				@extend %transform--active;
			}
		}
	}

	&:focus-visible {
		outline: revert;
		outline-color: var(--wsw-pink);
		outline-offset: 4px;
	}

	&:disabled {
		box-shadow: none;
		border-color: currentColor;
		background-color: inherit;
		color: var(--wsw-50);
		cursor: not-allowed;
	}
}

.primary,
#rcc-confirm-button {
	background: var(--wsw-0);
	border: var(--border);
	padding: 11px 18px;
	box-shadow: var(--button-shadow);
	transition: all 0.2s ease;
	align-self: flex-start;

	span {
		line-height: 24px;
	}

	&:enabled {
		&:hover,
		&:focus {
			@extend %transform--hover;
		}

		&:active {
			@extend %transform--active;
		}
	}

	&.hasIcon {
		padding: 11px 12px 11px 18px;
		justify-content: space-between;
	}
}

a.primary {
	&:hover,
	&:focus {
		@extend %transform--hover;
	}

	&:active {
		@extend %transform--active;
	}
}

.secondary {
	border: var(--border);
	padding: 11px 18px;
	transition: all 0.2s ease;
	align-self: flex-start;

	span {
		line-height: 24px;
	}

	&:enabled {
		&:hover,
		&:focus {
			border: var(--border);
		}

		&:active {
			border: var(--border);
			color: var(--wsw-100);
		}
	}

	&.hasIcon {
		padding: 11px 12px 11px 18px;
		justify-content: space-between;
	}
}

a.secondary {
	&:hover,
	&:focus {
		border: var(--border);
	}

	&:active {
		border: var(--border-gray);
		color: var(--wsw-100);
	}
}

.tertiary {
	gap: 6px;
	transition: all 0.2s ease;
	justify-content: flex-start;

	&:enabled {
		&:hover,
		&:focus,
		&:active {
			color: var(--wsw-75);
		}
	}
}

a.tertiary {
	span,
	svg {
		transition: all 0.2s ease;
	}

	&:hover span,
	&:hover svg,
	&:focus span,
	&:focus svg,
	&:active span,
	&:active svg {
		color: var(--wsw-75);
	}
}

.isReversed {
	flex-direction: row-reverse;
	&.hasIcon {
		padding: 11px 18px 11px 12px;
	}
}

.hasIcon:not(.tertiary) {
	justify-content: space-between;
}
