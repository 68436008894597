@use "styles/utils/media.module";
@use "./../Button/shared.module.scss";

.menu {
	&__menu {
		z-index: 202;
		position: relative;
	}

	&__button {
		padding: 0;
	}

	&__open {
		background-color: var(--wsw-25);
		height: 0;
		width: 100vw;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 201;
		transition: 0.3s all ease;
		overflow-y: scroll;
		overflow-y: overlay;

		@include media.min-width(lg) {
			display: inline-block;
		}

		ul {
			list-style: none;
		}
	}

	.opened {
		height: 100vh;
		padding: 0 32px;

		@include media.min-width(lg) {
			padding: 0 80px;
		}
	}

	&__left {
		display: flex;
		flex-direction: column;
		gap: 40px;
		padding: 180px 0 0;

		@include media.min-width(lg) {
			padding: 180px 0;
			gap: 80px;
			align-self: center;
		}
	}

	&__right {
		@include media.min-width(lg) {
			position: fixed;
			bottom: 40px;
			right: 80px;
		}
	}

	&__links {
		&__primary {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 0;

			@include media.min-width(lg) {
				gap: 40px;
			}
		}

		&__secondary {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 0;

			@include media.min-width(lg) {
				align-items: flex-end;
			}
		}
	}

	&__link {
		&__primary {
			font-feature-settings: var(--font-feature-settings-serif);
			font: var(--font-lg);
			transition: all 0.2s ease;
			color: var(--wsw-100);

			@include media.min-width(md) {
				font-feature-settings: var(--font-feature-settings-serif);
				font: var(--font-xxl);
			}

			&:hover,
			&:active,
			&:focus {
				color: var(--wsw-75);
			}
		}

		&__secondary {
			font: var(--font-link-sm);
			text-decoration: underline;
			color: var(--wsw-100);

			a {
				transition: all 0.2s ease;
			}

			&:hover a,
			&:active a,
			&:focus a {
				color: var(--wsw-75);
			}
		}
	}

	&__icons {
		display: flex;
		gap: 12px;
		padding-top: 40px;
		padding-bottom: 40px;

		@include media.min-width(lg) {
			flex-direction: column;
			padding-top: 0;
			align-items: flex-end;
		}
	}

	&__social {
		&:hover,
		&:focus {
			> a > div {
				@extend %transform--hover;
			}
		}

		&:active {
			> a > div {
				@extend %transform--active;
			}
		}
	}

	&__copywrite {
		font: var(--font-body);
		color: var(--wsw-50);
		padding: 40px 0;
		display: block;

		@include media.min-width(lg) {
			padding: 40px 0 0;
		}
	}
}
