@use "styles/utils/media.module";
@use "./shared.module.scss";

.button {
	@extend %button;
	color: var(--wsw-100);
	display: inline-block;
	padding: 2px;

	.svgWrapper {
		@extend %svgWrapper;
	}

	span {
		font-weight: 600;
		font-size: 0.75rem;
		line-height: 1rem;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: inherit;
	}

	&:enabled {
		&:hover,
		&:focus {
			.svgWrapper {
				@extend %transform--hover;
			}
		}
		&:active {
			.svgWrapper {
				@extend %transform--active;
			}
		}
	}

	&:focus-visible {
		outline: revert;
		outline-color: var(--wsw-pink);
		outline-offset: 4px;
	}

	&:disabled {
		color: var(--wsw-50);
		.svgWrapper {
			box-shadow: none;
			border-color: currentColor;
			color: inherit;
			background: inherit;
		}
	}
}

.secondary {
	.svgWrapper {
		box-shadow: none;
		background-color: inherit;
		transform: none;
		transition: all 0.2s ease;
		border-color: var(--wsw-75);
	}

	&:enabled {
		&:hover,
		&:focus,
		&:active {
			.svgWrapper {
				transform: none;
				box-shadow: none;
			}
		}

		&:hover .svgWrapper {
			border-color: var(--wsw-100);
		}

		&:active .svgWrapper {
			border-color: var(--wsw-75);
			color: var(--wsw-75);
		}
	}

	path {
		transition: all 0.2s ease;
	}

	// &:hover,
	// &:focus,
	// &:active {
	// 	transform: none;
	// 	box-shadow: none;

	// 	path {
	// 		stroke: var(--wsw-75);
	// 	}
	// }

	&:disabled {
		border-color: var(--wsw-50);
		color: var(--wsw-50);
	}
}

.showingLabel {
	display: flex;
	align-items: center;
	gap: 16px;
	color: var(--wsw-100);
}

.isReversed {
	flex-direction: row-reverse;
}

.small {
	height: 24px;
	width: 24px;
	padding: 0;
	.svgWrapper {
		height: 24px;
		width: 24px;
	}
}
