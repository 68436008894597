@use "styles/utils/media.module";

.heading {
	line-height: 1;
	color: var(--wsw-100);
}

.xxlMobile {
	font-feature-settings: var(--font-feature-settings-serif);
	font: var(--font-xxl);
}

.xlMobile {
	font-feature-settings: var(--font-feature-settings-serif);
	font: var(--font-xl);
}

.lgMobile {
	font-feature-settings: var(--font-feature-settings-serif);
	font: var(--font-lg);
}

.mdMobile {
	font-feature-settings: var(--font-feature-settings-serif);
	font: var(--font-md);
}

.smMobile {
	font-feature-settings: var(--font-feature-settings-serif);
	font: var(--font-sm);
}

.xsMobile {
	font-feature-settings: var(--font-feature-settings-serif);
	font: var(--font-xs);
}

.xxsMobile {
	font: var(--font-xxs);
	font-feature-settings: var(--font-feature-settings-sans);
	letter-spacing: 0.08em;
	text-transform: uppercase;
	color: var(--wsw-transparent);
}

.priceMobile {
	font: var(--font-price);
	font-feature-settings: var(--font-feature-settings-sans);
	letter-spacing: 0.08em;
	text-transform: uppercase;
}

@include media.min-width(md) {
	.xxlDesktop {
		font-feature-settings: var(--font-feature-settings-serif);
		font: var(--font-xxl);
	}

	.xlDesktop {
		font-feature-settings: var(--font-feature-settings-serif);
		font: var(--font-xl);
	}

	.lgDesktop {
		font-feature-settings: var(--font-feature-settings-serif);
		font: var(--font-lg);
	}
	.mdDesktop {
		font-feature-settings: var(--font-feature-settings-serif);
		font: var(--font-md);
	}

	.smDesktop {
		font-feature-settings: var(--font-feature-settings-serif);
		font: var(--font-sm);
	}

	.xsDesktop {
		font-feature-settings: var(--font-feature-settings-serif);
		font: var(--font-xs);
	}

	.xxsDesktop {
		font: var(--font-xxs);
		font-feature-settings: var(--font-feature-settings-sans);
		letter-spacing: 0.08em;
		text-transform: uppercase;
		color: var(--wsw-transparent);
	}

	.priceDesktop {
		font: var(--font-price);
		font-feature-settings: var(--font-feature-settings-sans);
		letter-spacing: 0.08em;
		text-transform: uppercase;
	}
}
