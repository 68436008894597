.main {
	padding: 160px 40px;
}

.error {
	.errorCode {
		display: block;
		font-size: 200%;
	}
}
