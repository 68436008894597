.blockquote {
	// font-size: 32px;
	line-height: 1.2;
	margin: 0;
	font: var(--font-lg);
	// font-family: var(--font-family-serif);
	color: var(--wsw-100);
	&::before {
		content: "“";
	}
	&::after {
		content: "”";
	}
}
