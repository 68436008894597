@use "styles/utils/media.module";

// Button reset
%button {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	background-color: rgba(0, 0, 0, 0);
	border: 0;
	padding: 0;
	margin: 0;
	cursor: pointer;
}

%svgWrapper {
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	background: var(--wsw-0);
	border: var(--border);
	box-sizing: border-box;
	box-shadow: var(--button-shadow);
	border-radius: 100px;
	transition: all 0.2s ease;
	flex: 0 0 auto;
	pointer-events: none;
}

%transform--hover {
	transform: translate3d(1px, 1px, 0);
	box-shadow: var(--button-shadow-hover);
}

%transform--active {
	transform: translate3d(2px, 2px, 0);
	box-shadow: none;
}
