@use "sass:math";

$breakpoints: (
	sm: 0,
	md: 46.875rem /* 750px */,
	lg: 63.9375em /* 1023px */,
	xl: 80em /* 1280px */,
	xxl: 87.5em /* 1400px */,
);

@function _strip-unit($number) {
	@if type-of($number) == "number" and not unitless($number) {
		$base: ($number * 0 + 1);
		@return math.div($number, $base);
	}

	@return $number;
}

@function _breakpoint-in-em($pxVal) {
	$val: _strip-unit($pxVal);

	@return math.div($val, 16) * 1em;
}

@mixin min-width($target, $orientation: null) {
	$breakpoint: map-get($breakpoints, $target);

	@if $orientation != null {
		@media (min-width: $breakpoint) and (orientation: $orientation) {
			@content;
		}
	} @else {
		@media (min-width: $breakpoint) {
			@content;
		}
	}
}

@mixin max-width($target, $orientation: null) {
	$breakpoint: map-get($breakpoints, $target) - 0.0625em;

	@if $orientation != null {
		@media (max-width: $breakpoint) and (orientation: $orientation) {
			@content;
		}
	} @else {
		@media (max-width: $breakpoint) {
			@content;
		}
	}
}

@mixin min-height($size, $orientation: null) {
	$breakpoint: _breakpoint-in-em($size);

	@if $orientation != null {
		@media (min-height: $breakpoint) and (orientation: $orientation) {
			@content;
		}
	} @else {
		@media (min-height: $breakpoint) {
			@content;
		}
	}
}

@mixin max-height($size, $orientation: null) {
	$breakpoint: _breakpoint-in-em($size);

	@if $orientation != null {
		@media (max-height: $breakpoint) and (orientation: $orientation) {
			@content;
		}
	} @else {
		@media (max-height: $breakpoint) {
			@content;
		}
	}
}

:export {
	sm: map-get($breakpoints, sm);
	md: map-get($breakpoints, md);
	lg: map-get($breakpoints, lg);
	xl: map-get($breakpoints, xl);
}
